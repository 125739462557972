<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-04-03 10:32:05
 * @LastEditTime: 2023-06-26 16:18
 * @Descripttion: 【作业考试】考试
-->
<style lang="scss" scoped>
.exam {
  @include innerPage;
  @include pageHead(-10px);
  @include defalutTable(calc(100% - 224px), 38px);
  @include pageFoot;

  .page-head {
    height: 224px;
    flex-wrap: wrap;
    padding-top: 0;

    .head-wrapper.column {
      margin-top: 12px;
    }

    .page-tab {
      justify-content: flex-start;
      margin-left: 24%;

      .tabs {
        border-bottom: none;
        justify-content: flex-start;
      }

      .more-subject {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #6c4ecb;
        margin-right: auto;
        margin-left: 60px;
        margin-top: 6px;
        cursor: pointer;

        &:hover {
          background: #8971d5;
        }

        .iconfont {
          font-size: 18px;
          text-align: center;
          line-height: 24px;
          color: #fff;
        }
      }
    }

    .single-search {
      width: 90.64%;

      .el-button:not(.search) {
        margin-left: 10px;
      }
    }
  }
}

.dialog-container {
  &--exam {
    box-sizing: border-box;
    padding: 46px 0 42px 0;

    .el-form-item:last-child {
      margin-bottom: 0;
    }

    ::v-deep .el-textarea__inner {
      min-height: 110px !important;
    }
  }
}
</style>

<template>
  <section class="exam">
    <div class="page-head">
      <div class="page-head--inner">
        <img class="bitmap" src="@assets/images/bitmap-head.png" alt />
        <div class="head-wrapper column">
          <breadcrumb />
          <div class="wrap-plate">
            <div class="single-search">
              <div class="search-ipt">
                <el-input v-model.trim="searchForm.query_word" maxlength="100" placeholder="搜索考试名称" clearable>
                  <span slot="prefix" class="iconfont">&#xe61c;</span>
                </el-input>
              </div>
              <el-button type="custom_primary search" size="medium" @click="searchData">搜索</el-button>
              <el-button type="custom_warning" size="medium" @click="showDialog">新建+</el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="page-tab">
        <ul class="tabs bold">
          <li class="tab" :class="{ current: searchForm.sysub_id == item.sysub_id }"
            v-for="(item, index) in teachSubject.slice(0, 5)" :key="item.sysub_id" @click="changeTab(index)">{{
              item.sysub_name }}</li>
        </ul>
        <el-dropdown class="more-subject" placement="bottom-start" v-if="teachSubject.length > 5">
          <p class="iconfont">&#xe8aa;</p>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in teachSubject.slice(5, teachSubject.length)" :key="item.sysub_id"
              @click.native="changeTab(index + 5)">
              {{ item.sysub_name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="table-box">
      <div class="table-inner" ref="tableInner">
        <el-table :data="tableData" height="100%">
          <el-table-column align="center" prop="tepap_title" label="试卷名称" min-width="11.68%" show-overflow-tooltip />
          <el-table-column align="center" prop="sysub_name" label="科目" min-width="6.2%" />
          <el-table-column align="center" prop="syedi_title" label="教材版本" min-width="7.55%" />
          <el-table-column align="center" label="考试类型" min-width="6.2%">
            <template slot-scope="scope">
              <p class="table-status" :class="{
                danger: scope.row.teexa_type == 20,
                warning: scope.row.teexa_type == 10,
              }">{{ (scope.row.teexa_type == 10 && "线上") || "线下" }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="teexa_starttime" label="考试时间" min-width="10.5%" />
          <el-table-column align="center" prop="teexa_endtime" label="交卷时间" min-width="10.5%" />
          <el-table-column align="center" label="考试状态" min-width="7%">
            <template slot-scope="scope">
              <p class="table-status" :class="{
                success: scope.row.teexa_status == 20,
                primary: scope.row.teexa_status == 10,
              }">
                {{
                  (scope.row.teexa_status == 20 && "考试中") ||
                  (scope.row.teexa_status == 30 && "已结束") ||
                  "未开始"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="班级人数" min-width="6.45%">
            <template slot-scope="scope">{{ scope.row.teexa_sumpeople }}人</template>
          </el-table-column>
          <el-table-column align="center" label="已交" min-width="6.33%">
            <template slot-scope="scope">{{ scope.row.teexa_subpeople }}人</template>
          </el-table-column>
          <el-table-column align="center" label="未交" min-width="5.87%">
            <template slot-scope="scope">{{ scope.row.teexa_sumpeople - scope.row.teexa_subpeople }}人</template>
          </el-table-column>
          <el-table-column align="center" label="批阅" min-width="6.43%">
            <template slot-scope="scope">{{ scope.row.teexa_reviewnum }}人</template>
          </el-table-column>
          <el-table-column align="center" label="操作" min-width="15.29%">
            <template slot-scope="scope">
              <div class="operation-buttons" style="width: 200px">
                <template v-if="scope.row.teexa_sendstatus == 2">
                  <el-button type="custom_primary" size="mini" plain @click="getExamDetail(scope.row.teexa_id)">
                    编辑
                  </el-button>
                  <el-button type="custom_success" size="mini" plain @click="distributeExam(scope.row.teexa_id)">
                    下发
                  </el-button>
                  <el-button type="custom_danger" size="mini" plain @click="delExam(scope.row.teexa_id)">删除</el-button>
                </template>
                <el-button v-if="scope.row.teexa_sendstatus == 1" type="custom_danger" size="mini" plain
                  :disabled="scope.row.teexa_status != 30" @click="toReadExam(scope.row.teexa_id)">批阅</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table-foot custom-foot">
        <customPagination :current="tablePage.pageIndex" :total="tablePage.total" @pageChange="flippingPage" />
      </div>
    </div>
    <!-- 新建、编辑考试 -->
    <el-dialog :title="(examForm.teexa_id && '编辑考试') || '新建考试'" :visible.sync="examDialog" width="534px"
      :show-close="false" @close="hideDialog">
      <div class="dialog-container--exam">
        <el-form ref="examForm" :model="examForm" :rules="examRules" label-width="68px">
          <el-form-item label="学期">
            <el-radio-group v-model="examForm.tetea_hom_semester" @input="getExamSubject(true)">
              <el-radio :label="1">上学期</el-radio>
              <el-radio :label="2">下学期</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="类型">
            <el-radio-group v-model="examForm.teexa_type">
              <el-radio :label="10">线上考试</el-radio>
              <el-radio :label="20">线下考试</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="科目" prop="sysub_id">
            <el-select v-model="examForm.sysub_id" placeholder="请选择" @change="getExamPaper(true)">
              <el-option v-for="item in subjectList" :key="item.sysub_id" :label="item.sysub_name"
                :value="item.sysub_id" />
            </el-select>
          </el-form-item>
          <el-form-item label="选择试卷" prop="tepap_id">
            <el-select v-model="examForm.tepap_id" placeholder="请选择试卷">
              <el-option v-for="item in examPapers" :key="item.tepap_id" :label="item.tepap_title"
                :value="item.tepap_id" />
            </el-select>
          </el-form-item>
          <el-form-item label="考试日期" prop="date">
            <el-date-picker popper-class="custom-date-picker" format="yyyy年M月d日" value-format="yyyy年M月d日"
              v-model="examForm.date" type="date" placeholder="选择日期" />
          </el-form-item>
          <el-form-item label="考试时间" prop="time">
            <el-time-picker prefix-icon="none" range-separator="至" start-placeholder="开始时间" end-placeholder="交卷时间"
              format="HH:mm" value-format="HH:mm" is-range v-model="examForm.time" :clearable="false" :editable="false" />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button type="custom_info" size="small" @click="hideDialog">取 消</el-button>
        <el-button type="custom_primary" size="small" @click="addExam" :disabled="btnload" v-loading="btnload">
          确 定
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  $examList,
  $getExam,
  $examSubject,
  $examPaper,
  $addExam,
  $editExam,
  $delExam,
  $distributeExam,
} from "@api/homeworkExam";
import { $getTeachSubject } from "@api/teaching";
import { formatTimeStamp, formatTime } from "@utils";
import customPagination from "@comp/customPagination";
import { mapState } from "vuex";
export default {
  name: "he_exam",
  components: { customPagination },
  computed: {
    ...mapState("common", ["subjectList"]),
  },
  data() {
    return {
      grade: null,
      class: null,
      searchForm: {}, // 筛选数据
      teachSubject: [], // 教师所授科目
      tableData: [], // 考试列表数据
      /* 表格分页参数 */
      tablePage: {
        pageIndex: 1,
        total: 0,
      },
      /* 考试表单参数 */
      examDialog: false,
      examForm: {
        tetea_hom_semester: 1,
        teexa_type: 10,
      },
      examSubject: [],
      examPapers: [],
      btnload: false,
      examRules: {
        sysub_id: [
          { required: true, message: "请选择考试相关科目", trigger: "change" },
        ],
        tepap_id: [
          { required: true, message: "请选择考试相关试卷", trigger: "change" },
        ],
        date: [
          { required: true, message: "请选择考试日期", trigger: "change" },
        ],
        time: [
          { required: true, message: "请选择考试时间", trigger: "change" },
        ],
      },
    };
  },
  async created() {
    let { id } = this.$route.params;
    id = id.split(",");
    this.grade = id[0];
    this.class = id[1];
    await this.getTeachSubject();
    this.getData();
  },
  /** 路由keepAlive为true时，页面数据更新 */
  async activated() {
    await this.getTeachSubject();
  },
  /** 页面跳转/返回前的回调 */
  beforeRouteLeave(to, from, next) {
    this.$routeAlive(to, from, next, ['HE_EXAMEREAD']);
  },
  methods: {
    /** 获取教材数据 */
    async getData() {
      let params = {
        ...this.searchForm,
        sccla_id: this.class,
        pageindex: this.tablePage.pageIndex,
      };
      let { data: res } = await $examList(params);
      this.tablePage.total = res.allcount;
      this.tableData = res.data;
      this.$forceUpdate();
    },
    /** 翻页 */
    flippingPage(val) {
      this.tableData = [];
      this.tablePage.pageIndex = val;
      this.getData();
    },
    /** 获取教师所授科目 */
    async getTeachSubject() {
      let { data } = await $getTeachSubject(this.class);
      this.teachSubject = data;
      if (this.searchForm.sysub_id) {
        data.map((item, index) => {
          if (item.sysub_id == this.searchForm.sysub_id) this.changeTab(index);
        })
      } else {
        this.searchForm = {
          ...this.searchForm,
          sysub_id: data[0].sysub_id,
        };
      }
    },
    /** 获取可用试卷科目 */
    async getExamSubject(delId) {
      this.examSubject = [];
      this.examPapers = [];
      if (delId) {
        delete this.examForm.sysub_id;
        delete this.examForm.tepap_id;
      }
      let params = {
        schedi_sub_grade: this.grade,
        schedi_sub_semester: this.examForm.tetea_hom_semester,
      };
      let { data } = await $examSubject(params);
      this.examSubject = data;
    },
    /** 获取可用试卷列表 */
    async getExamPaper(delId) {
      this.examPapers = [];
      if (delId) delete this.examForm.tepap_id;
      if (this.examForm.sysub_id && this.examForm.tetea_hom_semester) {
        let params = {
          tepap_grade: this.grade,
          tepap_semester: this.examForm.tetea_hom_semester,
          sysub_id: this.examForm.sysub_id,
        };
        let { data } = await $examPaper(params);
        this.examPapers = data;
      }
    },
    /** 添加考试 */
    addExam() {
      this.$refs.examForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let { date, time } = this.examForm;
          date = date.replace(/[年|月]/g, "/").replace(/日/g, "");
          let teexa_starttime = formatTimeStamp(`${date} ${time[0]}`);
          let teexa_endtime = formatTimeStamp(`${date} ${time[1]}`);
          let params = {
            ...this.examForm,
            teexa_starttime,
            teexa_endtime,
            tetea_hom_grade: this.grade,
            sccla_id: this.class,
          };
          delete params.date;
          delete params.time;
          if (params.teexa_id) return this.editExam(params); // 存在考试id，执行考试编辑
          let res = await $addExam(params);
          this.btnload = false;
          if (res) {
            this.hideDialog(); // 关闭表单弹窗
            this.getData();
            this.$message({
              type: "success",
              duration: 1500,
              message: "考试添加成功",
            });
          }
        }
      });
    },
    /**
     * 获取考试详情
     * @param {number} id 考试id
     */
    async getExamDetail(id) {
      let { data } = await $getExam(id);
      let examForm = {};
      if (data.teexa_starttime && data.teexa_endtime) {
        let date = formatTime(data.teexa_starttime * 1000, "YYYY年M月D日");
        let time = [
          formatTime(data.teexa_starttime * 1000, "HH:mm"),
          formatTime(data.teexa_endtime * 1000, "HH:mm"),
        ];
        examForm = { date, time };
      }
      let formKeys = [
        "teexa_id",
        "sysub_id",
        "tetea_hom_semester",
        "tepap_id",
        "teexa_type",
      ];
      formKeys.map((key) => {
        examForm[key] = data[key];
      });
      this.examForm = {
        ...examForm,
      };
      this.getExamSubject(false);
      this.getExamPaper(false);
      this.showDialog();
    },
    /**
     * 编辑考试
     * @param {object} params 表单数据
     */
    async editExam(params) {
      let res = await $editExam(params);
      this.btnload = false;
      if (res) {
        this.hideDialog(); // 关闭表单弹窗
        this.getData();
        this.$message({
          type: "success",
          duration: 1500,
          message: "考试编辑保存成功",
        });
      }
    },
    /**
     * 删除考试
     * @param {number} id 考试id
     */
    delExam(id) {
      this.$msgbox({
        title: "删除",
        message: "确认删除选中考试？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let res = await $delExam(id);
          if (res) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "考试已删除！",
            });
            this.tablePage.total = this.tablePage.total - 1 < 0 && 0 || this.tablePage.total - 1;
            if (this.tableData.length == 1 && this.tablePage.pageIndex != 1) {
              this.tablePage.pageIndex = this.tablePage.pageIndex - 1;
            }
            this.getData();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            duration: 1500,
            message: "操作已取消",
          });
        });
    },
    /**
     * 下发考试
     * @param {number} id 考试id
     */
    distributeExam(id) {
      this.$msgbox({
        title: "提示",
        message: "确认下发选中考试？",
        type: "info",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let res = await $distributeExam(id);
          if (res) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "考试已下发！请提醒学生完成考试",
            });
            this.getData();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            duration: 1500,
            message: "操作已取消",
          });
        });
    },
    /** 切换数据相关科目 */
    changeTab(index) {
      this.searchForm = {
        sccla_id: this.class,
        sysub_id: this.teachSubject[index].sysub_id,
      };
      if (index > 4) {
        let firData = this.teachSubject[index];
        let replaceData = this.teachSubject[4];
        this.teachSubject[4] = { ...firData };
        this.teachSubject[index] = { ...replaceData };
      }
      this.searchData();
    },
    /** 筛选数据 */
    searchData() {
      this.tableData = [];
      this.tablePage.pageIndex = 1;
      this.getData();
    },
    /** 开启弹窗 */
    showDialog() {
      this.examDialog = true;
    },
    /** 关闭弹窗 */
    hideDialog() {
      this.examDialog = false;
      this.examForm = {};
      this.$refs["examForm"].resetFields();
      this.examForm = {
        tetea_hom_semester: 1,
        teexa_type: 10,
      };
    },
    /**
     * 批阅考试
     * @param {number} id 考试id
     */
    toReadExam(id) {
      this.$router.push({
        name: "HE_EXAMEREAD",
        params: {
          class: this.$route.params.class,
          id: this.$route.params.id,
          exam: id,
        },
      });
    },
  },
};
</script>
